import {sha512} from "js-sha512";

const roles = {
    founder: {
        role: sha512("founder" + process.env.REACT_APP_FOUNDER_KEY),
        u_role: "founder",
        name: 'Учредитель',
    },
    manager: {
        role: sha512("manager" + process.env.REACT_APP_MANAGER_KEY),
        u_role: "manager",
        name: 'Менеджер'
    },
    admin: {
        role: sha512("admin" + process.env.REACT_APP_ADMIN_KEY),
        u_role: "admin",
        name: 'Администратор'
    }
}

export default roles;